<template>
  <ui-component-modal
    modalTitle="Delete note"
    :buttonLabelConfirm="'Delete'"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteNote"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div class="has-margin-bottom is-size-5 has-text-centered">
          <div class="has-text-weight-bold">
            Are you sure you want to delete note:
          </div>

          <div>{{ note.Subject }}</div>
          <div>{{ note.Body }}</div>
        </div>
        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            class="has-text-left"
            :label="'Yes, I am sure!'"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import noteProvider from '@/providers/note'

export default {
  components: {
    BaseCheckbox: () => import('@/components/UI/Form/BaseCheckbox'),
  },

  props: {
    note: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {},

  created() {},

  methods: {
    deleteNote() {
      let self = this

      if (!self.isDeleting) {
        noteProvider.methods
          .deleteNote(self.note.Id)
          .then((response) => {
            if (response.status === 204) {
              self.$emit('noteDeleted', self.note)

              self.onClickCancel()
            }
          })
          .catch((error) => {
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>
